.whirl.ringed:after {
  border-right-color: $nav-top-bg;
  border-top-color: $nav-top-bg;
  border-left-color: $nav-top-bg;
  border-bottom-color: $red;
}

.whirl {
  position: relative;

  &:before {
    background-color: #fff;
  }
}