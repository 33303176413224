@import "../bootstrap/functions";
@import "../bootstrap/variables";
@import "../bootstrap/mixins";

:root {
  --main-color: #333;
  --main-color-hover: rgb(00, 0, 0, 0.2);
  --track-color: rgb(104, 117, 217, 0.2);
  --mark-color: #ccc;
}

.customSlider {
  max-width: 100%;
  margin: auto;
  margin-top: 10px;
}

.customSlider-track {
  top: 8px;
  height: 4px;
  background: var(--track-color);
}

.customSlider-track.customSlider-track-0 {
  background: var(--main-color);
}

.customSlider-thumb {
  cursor: pointer;
  /*color for the thumb */
  background: var(--main-color);
  /* shape of the thumb: circle */
  width: 35px;
  height: 35px;
  border-radius: 100%;
  /* remove default outline when selected */
  color: #fff;
  outline: none;
  font-size: 11px;
  margin-top: -7px;
  text-align: center;
  padding-top: 10px;
  line-height: 1;
}

.customSlider-thumb:hover {
  box-shadow: 0 0 0 8px var(--main-color-hover);
}

.customSlider-mark {
  cursor: pointer;
  top: 6px;
  width: 1.5px;
  height: 8px;
  background-color: var(--mark-color);
}

.customSlider-mark.customSlider-mark-before {
  background-color: var(--main-color);
}

.customSlider-mark.customSlider-mark-active {
  display: none;
}

.vehicle_list > div {
  cursor: pointer;
}

.vehicle_list > div:hover {
  background-color: #222;
  border-left: 2px solid #d9de52;
  padding-left: 6px !important;
}

#map_sidebar {
  width: 225px;
  background-color: #363637;
  @include media-breakpoint-down(lg) {
    width: 175px;
  }
}